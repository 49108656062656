import moment from "moment";

// Checks whether an age is below a certain year.
export const isBelowAge = (date, yo) => moment().diff(moment(date, "YYYY-MM-DD"), "years") < yo;

// const date = new Date();
// const currentMonth = date.getMonth();
// const currentSeason = currentMonth < 10 ? date.getFullYear() : date.getFullYear() + 1;
// Registrations Helper - Check for if minor
export const isMinorConsistent = (dob, format = "YYYY-MM-DD", endOfSeason) => {
  if (endOfSeason === null || endOfSeason === undefined) {
    const date = new Date();
    const currentSeason = date.getMonth() < 10 ? date.getFullYear() : date.getFullYear() + 1;
    endOfSeason = `${currentSeason}-11-01`;
  }
  const startOfYearBirth = moment(dob, format).startOf("year");
  return moment(endOfSeason, "YYYY-MM-DD").diff(startOfYearBirth, "years") <= 18;
};

// Helper - Calcualte Age
export const calculateAge = (dob, format = "YYYY-MM-DD", endOfSeason) => {
  if (endOfSeason === null || endOfSeason === undefined) {
    const date = new Date();
    const currentSeason = date.getMonth() < 10 ? date.getFullYear() : date.getFullYear() + 1;
    endOfSeason = `${currentSeason}-11-01`;
  }
  const startOfYearBirth = moment(dob, format).startOf("year");
  return moment(endOfSeason, "YYYY-MM-DD").diff(startOfYearBirth, "years");
};
